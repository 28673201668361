import Link from 'next/link';
import { useRouter } from 'next/router';

const Home = () => {
  const router = useRouter();
  void router.push('/geplante-installationen');

  return (
    <h1>
      <Link href={'/geplante-installationen'}>Go to planned installations</Link>
    </h1>
  );
};

export default Home;
